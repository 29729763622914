<template>
  <div class="pwdcenter">
    <el-form ref="pwdloginForm" :model="pwdloginForm" :rules="rules">
      <el-form-item prop="mobile">
        <el-input v-model="pwdloginForm.mobile" placeholder="请输入您的手机号">
          <span slot="prefix" class="iconfont icon-shouji loginiconfont"></span>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="pwdloginForm.password"
          placeholder="请输入您的登陆密码"
          type="password"
        >
          <span
            slot="prefix"
            class="iconfont icon-yanzhengma loginiconfont"
          ></span>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :span="24">
          <el-col :span="16">
            <el-input
              v-model="pwdloginForm.code"
              placeholder="请输入验证码"
              type="text"
              auto-complete="off"
            >
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="login-code">
              <validCode :value.sync="code"></validCode>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- <el-form-item style="margin-top: -15px">
        <el-col :span="5">
          <el-checkbox label="自动登录"></el-checkbox>
        </el-col>
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import { getCaptcha } from "@/api/user";
import validCode from "./validCode"
export default {
  name: "PwdCenter",
  components: { validCode },
  data() {
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value.toLowerCase() !== this.code.toLowerCase()) {
        callback(new Error("验证码错误，请重新输入!"));
      } else {
        callback();
      }
    };
    return {
      code: "",
      image: "",
      pwdloginForm: {
        mobile: "",
        password: "",
        code: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入您的手机号码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入您的登陆密码", trigger: "blur" },
        ],
        code: [
          { validator: validateCode, trigger: 'blur' }
        ]
      },
    };
  },
  created() {
    // this.refreshCode();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验成功，返回登录信息
          this.$emit("loginByPassword", this.pwdloginForm);
        }
      });
    },
    refreshCode() {
      getCaptcha().then((res) => {
        const data = res.data;
        this.code = data.key;
        this.image = data.image;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-input:focus-within {
  span {
    color: #c4b1a7;
  }
}
</style>